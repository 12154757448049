import axios from 'axios';
import data from '@/data/data';
import { useToast } from "vue-toastification";
    
const toast = useToast()
const functions = {
    sortList(a, b) { 
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        return dateA > dateB ? 1 : -1; 
    }, 
    goBack(){
      window.history.back()
    },
    styleByResult(result){
        if(result>=50){
          return "background-color: forestgreen;"
        }
        else if(result<50 && result>=20){
          return "background-color: hsl(49, 90%, 45%);" 
        }
        return "background-color: red;"
    },
    async archiverQuestionnaire(idQuestionnaire){
      try{
        var payload = {
          "isArchived": true
        }
        const response =  await axios.patch(data.baseUrl + "thotea/api/questionnaires/"+idQuestionnaire+"/", payload)
        if(!JSON.stringify(response.data).includes('Error'))
          toast.success("Questionnaire archivé avec succès" )
      }
      catch(error){
        console.log(error)
      }
    },
    
    async udpateQuestionnaire(payload, id){
      try{
        console.log(payload)
        const response = await axios.patch(data.baseUrl + "thotea/api/questionnaires/"+id+"/", payload)
        if(!JSON.stringify(response.data).includes('Error'))
          toast.success("Questionnaire archivé avec succès" )
      }
      catch(error){
        console.log(error)
      }
    },

    async getQuestionnaireNotArchived(){
      try{
          const response = await axios.get(data.baseUrl + "thotea/api/questionnaires/not-archived-questionnaires")
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
      }catch(error) {
          console.log(error)
      } 
    },


    async deleteQuestionnaireAssigned(questionnaire_id){
      try{
        const response = await axios.delete(data.baseUrl + "thotea/api/assignquestionnaires/"+questionnaire_id+"/")
        if(!JSON.stringify(response.data).includes('Error'))
          toast.success("Questionnaire retiré avec succès" )
      }
      catch(error){
        console.log(error)
      }
    },
    
  

}
export default functions