<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222d;">
            <slot/>
            <div class="pl-5 pr-5">
                <p class="text-white text-left" >Groupe </p>
                <div class="row pl-5 pr-5">
                    <div class="col-md-4 pt-3 pb-3" style="background-color: white;">
                        <div class="group" v-for="group in groups" :key="group.id">
                            <p v-if="!hisGroup.includes(group.id)" @click="addToListToAdd(group.id)" :style="groupToAdd.includes(group.id) ? 'background-color:gold' : ''">{{ group.name }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <div class="text-center">
                            <button class="btn btn-primary mb-3" :disabled="groupToAdd.length==0" @click="add()"> >> </button>
                            <br>
                            <button class="btn btn-primary" :disabled="groupToRemove.length==0" @click="remove()"> &lt;&lt; </button>
                        </div>
                    </div>
                    <div class="col-md-4 pt-3 pb-3" style="background-color: white;">
                        <div class="group" v-for="group in hisGroup" :key="group">
                            <p @click="addToListToRemove(group)" :style="groupToRemove.includes(group) ? 'background-color:gold' : ''" >{{ groupName[group] }}</p>
                        </div>
                    </div>
                </div>
                    <button class="col-md-2 btn btn-primary mt-2" @click="saveGroup()"> Enregistrer</button>
                <hr>
                <!-- <div class="row  pt-3">
                    <p class="text-white text-left" >Role</p>
                    <br>
                    <div class="col-md-12">
                        <select class="form-group col-md-5" v-model="rightUser">
                            <option value="0"></option>
                            <option v-for="right in userRights"  :key="right.id" :value="right.id">
                                {{ right.description }}
                            </option>
                        </select>
                        <button class="col-md-5 btn btn-primary " @click="saveRight()"> Enregistrer role</button>
                    </div>
                    
                    
                </div> -->
                <hr>

                <div class="row  pt-3">
                    <p class="col-md-6 text-white text-left" >Réinitialiser mot de passe </p>
                    <button class="col-md-6 btn btn-primary " @click="reinit()" disabled> Reinitialiser</button>
                    
                </div>


                
                <button class="col-md-4 btn btn-secondary mt-4" @click="$emit('close')"> Annuler</button>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import { getAllGroups, resetGroup, updateUserRights } from '@/data/apiNess'
import { useToast } from 'vue-toastification'
const toast = useToast()
    export default{
        name: 'settingUser', 
        props:['selectedUser', 'groupUser', 'userRights', 'right'], 
        data(){
            return{
                category : 0,
                title : '', 
                file : '', 
                groups : [],
                groupName :[], 
                hisGroup : [], 
                groupToAdd : [], 
                groupToRemove : [], 
                rightUser : 0
            }
        },
        async mounted(){
            this.groups = await getAllGroups()
            this.groups.forEach( item=> {
                this.groupName[item.id] = item.name
            });
            this.groupUser.forEach(item=>{
                this.hisGroup.push(item)
            }) 
            this.rightUser = this.right
        },
        methods:{
            
            chooseFile() {
                document.getElementById('btnUpload').click();
            },
            reinit(){

            },
            addToListToAdd(id){
                if(!this.groupToAdd.includes(id)){
                    this.groupToAdd.push(id)
                }  else{
                   let index = this.groupToAdd.indexOf(id)
                   this.groupToAdd.splice(index, 1)
                } 
            },
            addToListToRemove(id){
                if(!this.groupToRemove.includes(id)){
                    this.groupToRemove.push(id)
                }   
                else{
                   let index = this.groupToRemove.indexOf(id)
                   this.groupToRemove.splice(index, 1)
                } 
            } ,
            add(){
                this.hisGroup.push(...this.groupToAdd)
                this.groupToAdd = []
            }, 
            remove(){
                this.groupToRemove.forEach(item => {
                    let index = this.hisGroup.indexOf(item);
                    if (index !== -1) {
                        this.hisGroup.splice(index, 1);
                    }
                });
                this.groupToRemove = []
            }, 
            async saveGroup(){
                const payload = {
                    "user_id" : this.selectedUser, 
                    "groups" : this.hisGroup
                }
                console.log(JSON.stringify(payload))
                const result = await resetGroup(JSON.stringify(payload))
                console.log(result)
                toast.success(result.Result)
                this.$emit('close')
                setTimeout(()=>{
                    window.location.reload()
                }, 1500)

            },
            async saveRight(){
                const payload = {
                    // "user_id" : this.selectedUser, 
                    "user_rights_id" : this.rightUser
                }
                console.log(JSON.stringify(payload))
                const result = await updateUserRights(this.selectedUser, payload)
                console.log(result)
                toast.success(result.Result)
                this.$emit('close')
                setTimeout(()=>{
                    window.location.reload()
                }, 1500)

            }
           
        }
    }
</script>
<style>
.group p{
    cursor: pointer;
}
</style>