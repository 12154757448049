

// const url = "https://bulma.groupviiorigin.com:8383/django/"
// const url = "http://149.202.159.49:33800/django/" //DEV
// const url = "http://149.202.159.49:34100/django/" //preprod
// const url = "http://192.168.3.20:8000/" //VM
// const url = 'http://localhost:8000/'
const url = "http://149.202.159.49:34300/django/" //farman
const data = {
    baseUrl : url,
}
export default data