<template>
    <div id="thotea">
        <Header :user="user"  page="questionnaire" logo="thotea"/>
        <div class="pr-3 pl-3 pt-2 100vh ">
            <back-button />
            <br>
            <div class="row pl-3   pr-3 ">
                <div class="col-md-8 ">
                    <div class=" pl-3 pr-3 pt-2 pl-5 pr-5">
                        <h3 class="pb-4  textTitle">Thèmes par catégorie</h3>
                        <br>
                        <select class="form-control " id="recruteur" v-model="categorie" @change = "onchangeCategory" >
                            <option value="">Tous les thèmes</option>
                            <option v-for="categorie in categories" :key="categorie.id" :value="categorie.id" >{{categorie.title}} </option>
                        </select>
                        
                        <div class="row" v-if="categorie!=''">
                            
                            <router-link to="/addQuestionnaire"><button class="btn btn-primary " >+ &nbsp; Nouveau Questionnaire</button></router-link>
                            <hr>
                            <ul  class="list-group" v-for="theme in themeFiltered" :key="theme.id">
                                <li class="list-group-item ">
                                    <router-link :to="{name: 'questionnaire', params:{'id': theme.id}}"><button class="btn btn-outline-info">{{theme.Title}}</button></router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="row " v-else>
                            <br>
                            <!-- <button class="btn btn-primary mt-3" @click="togglePopup()">+ &nbsp; Nouveau Questionnaire</button> -->
                            <router-link to="/addQuestionnaire"><button class="btn btn-primary mt-3 ml-3 " >+ &nbsp; Nouveau Questionnaire</button></router-link>
                            <div class="col-md-12" v-for="categorie in categories" :key="categorie.id">
                                <fieldset class="border pr-5 pl-5 pb-4 pt-2">
                                    <legend class="w-auto pt-4 text-white">{{ categorie.title }}</legend>
                                        <div class="row text-center">
                                            
                                            <ul class="list-group" v-for="theme in themeByCategorie(categorie.id)" :key="theme.id">
                                                <li class="list-group-item " style="background-color: #ffffff00">
                                                    <router-link :to="{name: 'questionnaire', params:{'id': theme.id}}"><button class="btn btn-outline-info">{{theme.Title}}</button></router-link>
                                                    <router-link :to="{name: 'editQuestionnaire', params:{'id': theme.id}}"> <i class="fa-regular fa-pen-to-square pl-2" style="color: white;"></i></router-link>
                                                    <a @click="archiverQuestionnaire(theme.id)" class="pl-2"><i class="fa-solid fa-trash " style="color:red"></i></a> 
                                                </li>
                                            </ul>
                                        </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" >
                    <div class="row pl-3 pr-4 " style="height: 35vh;border-radius: 2%;">
                        <div class="col-md-12 pb-4" style=" overflow-y: auto">
                            <h3 class="p-3 textTitle">Questionnaire selon profil</h3>
                            <hr>
                            <select class="form-control " v-model="job" @change="changeJob">
                                <option value="">Sélectionnez un profil</option>
                                <option :value="item.id" v-for="item in jobs" :key="item.id">{{ item.title }}</option>
                            </select>
                            
                            <br/>
                                    <button class=" btn btn-primary m-1 " v-if="job!=''" @click="togglePopup()"  data-toggle="tooltip" data-placement="top" title="Ajouter questionnaire"><i class="fas fa-add" color="white"></i> Ajouter questionnaire</button>
                            
                            <ul class="list-group" v-if="questionnaireAssigned.length!=0">
                                <li class="list-group-item justify-content-center align-items-center" v-for="item in questionnaireAssigned" :key="item.id">
                                    <span class="float-left pt-2">{{ item.Title }}</span>
                                    <span class="float-right "><button class="btn btn-danger"  data-toggle="tooltip" data-placement="top" title="retirer" @click="retirer(item.id, job)" ><i class="fas fa-times-circle" color="white" ></i></button></span>
                                </li>
                            </ul>
                            <!-- <div v-else :hidden="job==''">
                                <table class="table">
                                    <thead>

                                    </thead>
                                    <tbody>
                                        <tr v-for="job in jobs" :key="job.id">
                                            <td class="text-white">{{ job.title }}</td>
                                            <td class="text-white">{{themeByJob(job.id)}}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div> -->
                            <p class="pt-3" v-else :hidden="job==''">Aucun questionnaire assigné</p>
                        </div>
                    </div>
                </div>
            </div>
        <AddQuestionnaireToJob v-if="popupTrigger" :togglePopup="togglePopup" :questionnaires="themes" :idjob="job" :jobs="jobs" :questionnaireAssigned="questionnaireAssigned">
            
        </AddQuestionnaireToJob>
        </div>   
    </div>

    <Chatroom />
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
      
<script>
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import axios from 'axios';
    import data from '@/data/data';
    import functions from '@/data/function'
    import { useToast } from "vue-toastification";
    
    const toast = useToast()
    import AddQuestionnaireToJob from './AddQuestionnaireToJob.vue';
import BackButton from '@/components/shared/BackButton.vue';
      export default {
        name: 'listQuestionnaire',
        props: {
         
        },
        components:{Header,
                AddQuestionnaireToJob, BackButton
            },
        
        data(){
            return{
                user : store.state.user,
                categorie : "",
                categories : [], 
                themes : [], 
                themeFiltered : [],

                
                jobs : [], 
                job : "",
                questionnaireAssigned : [],
                popupTrigger : false
            }
        },
        mounted(){
            
            if(this.user.profile.user_rights_id<=2 ) this.$router.push("/")
            axios.get(data.baseUrl+"authentication/api/api/jobs")
            .then(response=>{
                this.jobs = response.data
            }).catch(error=> console.log(error))
           axios.get(data.baseUrl+"thotea/api/categories")
            .then(response=>{
                this.categories = response.data
                
            }).catch(error=> console.log(error))
            axios.get(data.baseUrl+"thotea/api/questionnaires/not-archived-questionnaires")
            .then(response=>{
                 this.themes = response.data
                 this.themeFiltered = this.themes
            }).catch(error=> console.log(error))
            
            
        },
        methods:{
            onchangeCategory(){
                if(this.categorie!=""){
                    this.themeFiltered = this.themes.filter(item=>{
                        return item.category==parseInt(this.categorie)
                    })
                } 
                else this.themeFiltered = this.themes
            },
            
            themeByCategorie(categ){
                return this.themes.filter(item=>{
                    return item.category==parseInt(categ)
                })
            }, 
            changeJob(){
                axios.get(data.baseUrl+"thotea/api/questionnaires")
                .then(response=>{
                    this.questionnaireAssigned= response.data.filter(item=>{
                        return item.linkedJob.includes(this.job)
                    })
                }).catch(error=> console.log(error))
                
            },
            async themeByJob(job) {
                try {
                    const response = await axios.get(data.baseUrl + "thotea/api/questionnaires");
                    const filteredData = response.data.filter(item => {return item.linkedJob.includes(job)});
                    console.log(filteredData)
                    return filteredData;
                } catch (error) {
                    console.log(error);
                    throw error; // Renvoyer l'erreur pour une gestion ultérieure si nécessaire
                }
            },
            togglePopup(){
                this.popupTrigger=!this.popupTrigger
            }, 
            retirer(idQuestionnaire, idJob){
                axios.post(data.baseUrl+"thotea/api/delProfileInQuestionnaire/", {id: idQuestionnaire, job_id: idJob})
                .then(response=>{
                    
                    if(!(JSON.stringify(response.data).includes('Error'))){
                        const index = this.questionnaireAssigned.findIndex(item=>item.id==idQuestionnaire)
                        this.questionnaireAssigned.splice(index, 1)
                        toast.success("Questionnaire retiré avec succès")
                    }
                    else toast.error(JSON.stringify(response.data))
                    
                }).catch(error=> console.log(error))
            },
            archiverQuestionnaire(id){
                functions.archiverQuestionnaire(id).then(() => {
                     window.location.reload();
                });
                
            }
            
        },
        
        
      }
</script>
<style>
.case{
    background-color: #ffffff33;
    border-radius: 1%;
}
.navCandidate{
    background-color: rgb(43, 124, 124);
    color: white;
}
.style{
    background-color: rgb(242, 196, 14)
}
@import "~bootstrap/dist/css/bootstrap.css";
</style>