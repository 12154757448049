<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1;">
            <slot/>
            <div class="pl-5 pr-5">
            <!-- <form> -->
                
                <div class="form-group">
                    <label class="text-white float-left">Titre</label>
                    <input class="form-control fieldLogin" v-model="title" required/>
                </div>
                
                <hr>
                <div class="row pr-5 pl-5 pt-3">
                    <button class="col-6 btn btn-primary" @click="addCateg()" :disabled="title==''"> Confirmer</button>
                    <div class="col-2"></div>
                    <button class="col-4 btn btn-secondary" @click="$emit('close')"> Annuler</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
    import {  addCompany } from '@/data/apiThemis'
import { useToast } from 'vue-toastification'
const toast = useToast()
    export default{
        name: 'addQuestionFaq', 
        props:['categories'], 
        data(){
            return{
                category : 0,
                title : '', 
                file : ''
            }
        },
        mounted(){
            
        },
        methods:{
            async addCateg(){
                const payload = {
                    "titre" :  this.title
                }
                const result = await addCompany(payload)
                toast.success("Catégorie ajoutée avec succès", result.titre)
                setTimeout(()=>{
                    window.location.reload()
                }, 1500)
            }, 
            chooseFile() {
                document.getElementById('btnUpload').click();
            },
           
        }
    }
</script>