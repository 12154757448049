<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1;">
            <slot/>
            <div class="pl-5 pr-5">
            <!-- <form> -->
                <div class="form-group ">
                    <label class="text-white  float-left">Catégorie</label>
                    <select class="form-control fieldLogin" v-model="category">
                        <option value=0>Sélectionner une catégorie</option>
                        <option :value="category.id" v-for="category in categories" :key="category.id">
                            {{ category.titre }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="text-white float-left">Titre</label>
                    <input class="form-control fieldLogin" v-model="title" required/>
                </div>
                <div class="form-group">
                    <label class="text-white  float-left">Fichier PDF : {{ filename }}</label>
                    <input type="file" ref="file"  id="btnUpload" @change="changeFile()" style="display: none;" />
                </div>
                <br>
                <button align="center" class="btn btn-primary" @click="chooseFile()"><i class="fa-solid fa-upload" ></i> Ajouter un PDF</button>
                <hr>
                
                <div class="row pr-5 pl-5 pt-3">
                    <button class="col-6 btn btn-primary" @click="addPdf()" :disabled="title=='' || category==0 || isFile==0 "> Confirmer</button>
                    <div class="col-2"></div>
                    <button class="col-4 btn btn-secondary" @click="$emit('close')"> Annuler</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
    import { addPdf } from '@/data/apiThemis'
import { useToast } from 'vue-toastification'
const toast = useToast()
    export default{
        name: 'addQuestionFaq', 
        props:['categories'], 
        data(){
            return{
                category : 0,
                title : '', 
                file : '', 
                isFile :0, 
                filename:''
            }
        },
        mounted(){
            
        },
        methods:{
            async addPdf(){
                // const fileInput = document.getElementById('btnUpload');
                // const file = fileInput.files[0];
                const file  = this.$refs.file.files.item(0)
                console.log(file)
                const formData = new FormData()
                formData.append("categories", parseInt(this.category))
                formData.append("titre", this.title)
                formData.append("upload", file)
                formData.append("name", file.name)
                // console.log(formData)
                await addPdf(formData)
                toast.success("Fichier ajouté")
                setTimeout(()=>{
                    window.location.reload()
                }, 1500)
            }, 
            chooseFile() {
                document.getElementById('btnUpload').click();
            },
            changeFile(){
                const fileInput = document.getElementById('btnUpload');
                if (fileInput.files.length > 0) {
                    const file = this.$refs.file.files.item(0)
                    this.filename = file.name
                    this.isFile = 1;
                } else {
                    this.isFile = 0
                }
            }
           
        }
    }
</script>