<template>
    <div class="container-fluid accueil">
        <br><br>
        <BackButton/>
        <div class="row justify-content-center align-items-center cardLogin"> 
            <div class="col-12 col-md-8">
                <div class="card formulaire text-white">
                    <div class="row p-5">
                        <div class="col-md-3 mt-5 p-3">
                            <ul class="list-group mt-5" style="cursor: pointer;list-style-type: none;">
                                <li class="list-item-group p-3" :class="divMyAccount? 'active' : ''" @click="divMyAccount=true; divUsers = false" hidden >Mon compte</li>
                                <li class="list-item-group p-3" :class="divUsers? 'active' : ''" @click="divMyAccount=false; divUsers = true">Utilisateurs</li>
                            </ul>
                        </div>
                        <div class="col-md-9">
                            <div class="pl-5 pr-5" v-if="divMyAccount">
                                <!-- <form> -->
                                    <h3>Changer mon mot de passe</h3>
                                    <br>
                                    <hr>
                                    <div class="form-group ">
                                        <label class="text-white  float-left">Mot de passe actuel</label>
                                        <input class="form-control fieldLogin" v-model="mypassword"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white float-left">Nouveau mot de passe</label>
                                        <input class="form-control fieldLogin" v-model="newPassword"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white float-left">Saisir le mot de passe à nouveau</label>
                                        <input class="form-control fieldLogin" v-model="newPassword2"/>
                                    </div>
                                    <br>
                                    <button align="center" class="btn col-3 btn-primary" @click="changePassword()"> Valider</button>
                                    
                                    <!-- <div class="row pr-5 pl-5 pt-3">
                                        <button class="col-6 btn btn-primary" @click="addPdf()"> confirmer</button>
                                        <div class="col-2"></div>
                                        <button class="col-4 btn btn-secondary" > Retour</button>
                                    </div> -->
                                <!-- </form> -->
                            </div>
                            <br>
                            <div class="pl-5 pr-5" v-if="divUsers">
                                
                                <h3>Groupe des utilisateurs</h3>
                                    <br>
                                    <hr>
                                <input class="form-control fieldLogin" v-model="search" placeholder="Rechercher un utilisateur"/>

                                <hr>
                                <div class="divUser pt-2" style="height: 50vh; overflow-y: auto;overflow-x: hidden;/*scrollbar-color: transparent transparent;*/">
                                    <div class=" pt-2" v-for="userRight in filteredUsersByRights" :key="userRight.id">
                                        <strong class="text-left">{{ userRight.description }}</strong>
                                        <div class="row p-2" v-for="user in userRight.users " :key="user.id">
                                            <div class="col-md-5 text-left">
                                                <small>{{ user.last_name }} {{ user.first_name }}</small>
                                            </div>
                                           
                                            <div class="col-md-6">
                                                <small>{{user.email}}</small>
                                            </div>
                                            <div class="col-md-1">
                                                <a @click="selectedUser=user.id;groupUser =user.groups; right= user.extension[0]? user.extension[0].user_rights_id : ''; settingUser=true"><i class="fa-solid fa-pen-to-square"></i></a>
                                            </div>
                                        </div>
                                        <div class="text-center" v-if="userRight.users.length==0">
                                            <small class="text-center">Aucun résultat</small>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Chatroom/>
    </div>
    <SettingUser :selectedUser="selectedUser" :groupUser="groupUser" :right="right" :userRights="userRights"  v-if="settingUser" @close="settingUser = false">
        <button type="button" class="btn  float-right text-white" @click="settingUser=false; " >x</button>
    </SettingUser>
</template>
<script>
import Chatroom from '@/components/chatroom/Chatroom.vue';
import store from '@/store';
import BackButton from '@/components/shared/BackButton.vue';
import SettingUser from '@/views/Accueil/popup/SettingUser.vue'
// import { useToast } from 'vue-toastification';
import { getAllUsers, userRights} from '@/data/apiNess'
// const toast = useToast()
export default {
    name: 'accueilPage',
    data() {
        return {
            user: store.state.user,
            mypassword : '', 
            newPassword : '', 
            newPassword2 : '', 
            search : '',
            allusers : [], 
            divMyAccount : false,
            divUsers : true,
            settingUser  : false,
            selectedUser: null,
            groupUser:[], 
            userRights : [], 
            right :0
        };
    },
    async mounted() {
        if (this.user) {
            if (this.user.id == -1)
                this.$router.push('/login');
            this.allusers = await getAllUsers()
            this.userRights = await userRights()
        }
        else
            this.$router.push('/login');
    },
    methods:{
        logout(){
                this.$store.dispatch('logout')
                .then(response=> {
                    console.log(response)
                    // this.toggleViewWithBool(false)
                    this.$router.push('/login')
                }).catch(error=>{
                    console.log(error)
                })
        },
        changePassword(){

        }
       
    },
    computed: {
        usersByRights() {
            return this.userRights.map(right => {
                return {
                    ...right,
                    users: this.allusers.filter(user => user.extension[0] ? user.extension[0].user_rights_id == right.id : '')
                };
            });
        },
        filteredUsersByRights() {
            return this.userRights.map(right => {
                const filteredUsers = this.allusers.filter(user => {
                    return (user.extension[0] ? user.extension[0].user_rights_id == right.id : '1<2') &&
                        (user.last_name.toLowerCase().includes(this.search.toLowerCase()) || user.first_name.toLowerCase().includes(this.search.toLowerCase()) );
                });
                return {
                    ...right,
                    users: filteredUsers
                };
            });
        }
    },
    components: { Chatroom, BackButton, SettingUser }
}
</script>
<style>
    .accueil {
        background-image: url('@/assets/login_page/background_home.jpg')
    }
    .accueil {
    min-height: 100vh;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.card-header {
    background-color: #237eaf;
    border-radius: 2% / 22%;
    max-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header img {
    float: left;
}

.card-header .float-right {
    display: flex;
    align-items: center;
}

.card-header .float-right img {
    margin-left: 0.5rem;
}

.content-area {
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
}

.branch-img {
    width: 100%;
    max-width: 200px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .card-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .content-area {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .branch-img {
        display: none;
    }
}

@media (max-width: 480px) {
    .content-area {
        flex-direction: column;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .card-header {
        height: auto;
    }

    .card-header .float-right {
        margin-top: 0.5rem;
    }
    
}
::-webkit-scrollbar {
        width: 2px;
        margin-left: 2px;
        background-color: rgba(255, 255, 255, 0.158); /* couleur du "pouce" de la scrollbar */
    }
</style>
