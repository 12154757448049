
import data from "@/data/data"
import axios from 'axios'

import { useToast } from "vue-toastification";
const toast = useToast()

// Droit de Travail
export async function getTitles (){
    try{
        const response = await axios.get(data.baseUrl + "/themis/titres/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function getSousTitles (id){
    try{
       
        const response = await axios.get(data.baseUrl + "/themis/soustitres/?id="+id)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function getContent (idSubtitle){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/contenuarticles/?id="+idSubtitle)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


//Convention collectives 
export async function getCCTitle (){
    try{
       
        const response = await axios.get(data.baseUrl + "/themis/cctitre/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function getCCSubtitle (){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/ccsoustitre/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function getCCArticles (idSubtitle){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/ccarticle/?idSousTitre="+idSubtitle)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function getTitleCompany (){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/reglement-titres/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function addCompany (payload){
    try{
       
        const response = await axios.post(data.baseUrl + "themis/reglement-titres/", payload)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function deleteCompany (id){
    try{
       
        const response = await axios.delete(data.baseUrl + "themis/reglement-titres/"+id+"/")
        
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

//FAQ

export async function getFAQTitle (){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/faqtitre/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
//GEt FAQ par titre
export async function getFAQByTitle(idTitle){
    try{
        const response = await axios.get(data.baseUrl + "themis/faq/?id="+ idTitle)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function getFAQ(){
    try{
        
        const response = await axios.get(data.baseUrl + "themis/faq")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
//add questions in FAQ
export async function addQuestion(payload){
    try{
        const response = await axios.post(data.baseUrl + "themis/faqquestion/", payload)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function getAllQuestionFaq(){
    try{
        const response = await axios.get(data.baseUrl + "themis/faqquestion")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
//update question FAQ

export async function updateFaqQuestion(questionId, payload){
    try{
        const response = await axios.put(data.baseUrl + "themis/faqquestion/"+questionId+"/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
//Mon entreprise : pdf

export async function getPdfEntreprise(){
    try{
        const response = await axios.get(data.baseUrl + "themis/pdf")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function addPdf(payload){
    try{
        const response = await axios.post(data.baseUrl + "themis/pdf/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function getFavoris (){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/favorites/")
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function getFavorisByUser(idUser){
    try{
       
        const response = await axios.get(data.baseUrl + "themis/favorites/?id="+idUser)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function addFavoris (payload){
    try{
       
        const response = await axios.post(data.baseUrl + "themis/favorites/", payload)
    
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function removefavoris (payload){
    try{
        const response = await axios.delete(data.baseUrl + "themis/favorites/", {data : payload})
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function getLaborCodeById (idLaborCode){
    try{
        const response = await axios.get(data.baseUrl + "themis/contenuarticles/"+idLaborCode+"/")
        
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function getNotification (){
    try{
        const response = await axios.get(data.baseUrl + "themis/notification/")
        
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function updateNotif (id, payload){
    try{
        console.log(payload)
        const response = await axios.put(data.baseUrl + "themis/notification/"+id+"/", payload)
        
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function addNotification (payload){
    try{
        const response = await axios.post(data.baseUrl + "themis/notification/", payload)
        
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
