<template>
    <div id="thotea">
    <Header :user="user" page="questionnaire" logo="thotea"/>
    <div class="" >
        <!-- <button class="thoteaBg btn rounded-circle float-left m-2" @click="back()"><i class="fas fa-arrow-left" color="white"></i></button> -->
        <back-button/>
        <div class="p-5 ">
            <div class="p-5 pl-5 pr-5 " >
            <h1 class="textTitle">Ajout questionnaire</h1>
            <hr>
            <form >
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="title">Titre</label>
                    <input type="text" class="form-control fieldLogin"
                    :class="{'is-invalid' : isValidTitle(title) && titleBlured} "
                    :blur="titleBlured = true"
                    v-model="title" id="title"/>
                    <div class="invalid-feedback" style="font-size: 20px;">Ce questionnaire existe déjà</div>
                </div>
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="categorie">Catégories</label>
                    <select class="form-control pl-4 pr-4 fieldLogin"  v-model="categorie">
                        <option value="">Sélectionnez une catégorie</option>
                        <option v-for="categorie in categories" :key="categorie.id" :value="categorie.id" >{{categorie.title}} </option>
                    </select>
                    
                </div>
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="job">Profil</label>
                    <select class="form-control fieldLogin" v-model="job">
                                <option value="">Sélectionnez un profil</option>
                                <option :value="item.id" v-for="item in jobs" :key="item.id">{{ item.title }}</option>
                    </select>
                </div>
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="questions">Questions</label>
                    <button type="button" class="btn btn-info  float-right" data-toggle="tooltip" data-placement="top" title="Ajouter question" @click="showAddQuestion=!showAddQuestion">+  Ajouter une question</button>
                    <button type="button" class="btn btn-warning mr-1 float-right"  data-toggle="tooltip" data-placement="top" title="Importer question" @click="showAddQuestionFile=!showAddQuestionFile"><i class="fas fa-file-excel"></i> Importer fichier</button>
                    <br><br>
                    <div class="card" style="background-color: rgba(255, 255, 255, 0);">
                        <div class="row ">
                            <div class="col-12 p-3" style="max-height: 45vh;overflow-y: auto;"  v-if="questions.length>0">
                                <div  v-for="(question, index) in questions" :key="index">
                                    <button type="button" class="btn  float-right mb-1" style="margin-top: -5px;" @click="deleteQuestion(index)"><i class="fas fa-trash" color="red"></i></button>
                                    
                                    <b class="text-white" >{{ question.question }}</b>
                                    <ul class="list-group">
                                        <li class="list-group-item" v-for="(answer, index) in question.answers.split('|||')" :key="index">
                                            <span class="float-left">{{ answer }}</span>
                                           
                                            <button class="btn float-right" v-if="question.correctAnswer.toString().trim().includes(answer.trim())"><i class="fas fa-check" color="green"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-6 border p-3 pl-5 pr-5 justify-content-center" v-if="showAddQuestion">
                                <button type="button" class="btn btn-danger float-right mb-1" @click="showAddQuestion=!showAddQuestion">x</button>
                                 <br><br>
                                <div class="form-group">
                                    <label for="title" class="text-white">Question</label>
                                    <input type="text" class="form-control fieldLogin" v-model="question" id="title"/>
                                </div>
                                <div class="form-group">
                                    <label for="responses"  class="text-white">Réponse</label>
                                    <input type="text" class="form-control fieldLogin" v-model="responses" id="responses"/>
                                </div>
                                <div class="form-group">
                                    <label for="correctAnswer"  class="text-white">Bonne réponse</label>
                                    <input type="text" class="form-control fieldLogin" v-model="correctAnswer" id="correctAnswer"/>
                                </div>
                                <div class="form-group">
                                    <label for="level"  class="text-white">Niveau</label>
                                    <input type="number" min="1" max="3" class="form-control fieldLogin" v-model="difficulty" id="level"/>
                                </div>
                                <button type="button" class="btn btn-success" @click="addQuestion">Ajouter question</button>
                                
                            </div>
                            <div class="col-6 p-3 justify-content-center" v-if="showAddQuestionFile" align="center">
                                <button type="button" class="btn btn-danger float-right mb-1" @click="showAddQuestionFile=!showAddQuestionFile">x</button>
                                <br><br>
                                <div class="form-group">
                                    <input type="file" class="form-control fieldLogin" id="file"  @change="uploadFile($event)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
            </div>
            <br>
            <!-- <button class="btn btn-secondary" @click="togglePopup()">Fermer</button> -->
            <div align="center">
                <button class="btn btn-warning text-white btn-lg btn-block  ml-1" style="width: 50%;" @click="addQuestionnaire()" :disabled="!isValidFormQuestion()">Ajouter</button>
            </div>
        </div>
    </div>  
    <Chatroom />
</div>
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
import data from '@/data/data';
import functions from '@/data/function';
import store from '@/store/index'
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import { useToast } from "vue-toastification";

const toast = useToast()
export default {
        name: 'addQuestionnaire',
        props: ['togglePopup'],
        components:{Header, BackButton}, 
        data(){
            return{
                user : store.state.user,
                job : '', 
                categorie : '', 
                title : '',
                showAddQuestion : false,
                questions: [],
                jobs:[],
                categories : [],
                themes:[],
                question : '',
                responses : '',
                correctAnswer : '',
                difficulty : 1, 
                showAddQuestionFile : false, 
                titleBlured : false
            }
        },
        mounted(){
            if(this.user.profile.user_rights_id<=2 ) this.$router.push("/")
            axios.get(data.baseUrl+"authentication/api/api/jobs")
            .then(response=>{
                this.jobs = response.data
            }).catch(error=> console.log(error))
           axios.get(data.baseUrl+"thotea/api/categories")
            .then(response=>{
                this.categories = response.data
            }).catch(error=> console.log(error))
            axios.get(data.baseUrl+"thotea/api/questionnaires")
            .then(response=>{
                 this.themes = response.data
            }).catch(error=> console.log(error))
        },
        methods:{
            isValidTitle(title){
                const existeDeja = this.themes.some(theme => theme.Title === title);
                return existeDeja
            },
            isValidFormQuestion(){
                if(this.job!='' && this.categorie!='' && this.title!='' && this.questions.length>0 && !this.isValidTitle(this.title))
                    return true
                return false
            },  
            addQuestion(){
                this.questions.push({
                    question : this.question, 
                    answers : this.responses,
                    difficulty : this.difficulty,
                    correctAnswer : this.correctAnswer.toString()
                })
                this.question = ''
                this.responses = ''
                this.difficulty = ''
                this.correctAnswer = ''
            },

            
            addQuestionnaire(){
                var payload = {
                    "newQuestionnaire" : {
                        "Title" :  this.title,
                        "linkedJob" : [this.job],
                        "category" : this.categorie,
                        "isArchived" : false, 
                        "dateArchived" : null,
                    },"questions" : this.questions
                }
                // console.log(payload)
                axios.post(data.baseUrl + 'thotea/addQuestionnaire/', payload)
                    .then(response=>{
                        if(response.data){
                            toast.success("Questionnaire enregistré")
                            this.$router.push("/listquestionnaire")
                        }
                    }).catch(error=> toast.error(error))
            }, 
            uploadFile(event){
                let xlsxfile = event.target.files ? event.target.files[0] : null;
                readXlsxFile(xlsxfile).then((rows) => {
                    const header = rows[0];
                    const expectedHeader = ["Question", "Answers", "Correct Answer", "Difficulty"];
                    if (!header.every((col, index) => col === expectedHeader[index])) {
                        toast.error("Erreur : La première ligne du fichier n'est pas conforme.");
                        return;
                    }
                    const questionsSet = new Set(); 
                    rows.forEach((element, index) => {
                        if(index!=0){
                            if(element.includes(null)){
                                toast.warning("Erreur : une case est vide à la ligne "+(index+1));
                                return;
                            }
                            if(element[0].length<5){
                                toast.warning("Vérifier la question à la ligne "+(index+1));
                                return;
                            }
                            if(!element[1].includes('|||')){
                                toast.warning("Erreur : ajouter des réponses multiples  à la ligne "+(index+1));
                                return;
                            }
                            const rep = element[2].split('|||')
                            let count =0
                            rep.forEach(item=>{
                                if(element[1].split('|||').includes(item)){
                                    count++;
                                }
                            })
                            if(count!=rep.length){
                                toast.warning("Erreur : la bonne réponse  à la ligne "+(index+1) + " est introuvable dans le choix multiple");
                                return;
                            }
                            
                            if(element[3]>3 || element[3]<1){
                                toast.warning("Erreur : Niveau renseigné non correct à la ligne "+(index+1));
                                return;
                            }
                            if (questionsSet.has(element[0])) {
                                toast.warning("Erreur : Question dupliquée à la ligne " + (index + 1));
                                return;
                            } else {
                                questionsSet.add(element[0]);
                            }
                            this.questions.push({
                                question : element[0], 
                                answers : element[1],
                                correctAnswer : element[2],
                                difficulty : element[3],
                            })
                        }
                    }); 
                }).catch((error) => {
                    toast.error("Erreur lors de la lecture du fichier:", error);
                });
            }, 
            deleteQuestion(index){
                this.questions.splice(index,1)
            },
            back(){
                functions.goBack()
            }
        }
    }
</script>
<style  scoped>
.paddingAdd{
    padding: 5%;
}
.btn:disabled {
    background-color: grey; 
    color: white; 
    cursor: not-allowed;
    opacity: 0.6; 
    border-color: grey;
}
</style>