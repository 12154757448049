<template>
    <div id="thotea">
        <Header :user="user" page="questionnaire" logo="thotea" />
        <back-button/>
        <div class="pr-3 pl-3 pt-2 100vh " >
            
            <br>
            <div class="row pl-3 pt-2  pr-5 pl-5 ">
                <div class="col-5 " >
                    <div class="p-3">
                        <h3 class="pb-4 textTitle">Thèmes sans categorie</h3>
                        <div class="row ">
                            <br>
                            <div class="col-12">
                                <fieldset class="border pr-5 pl-5 pb-4 pt-2">
                                    <legend class="w-auto pt-4 text-white">{{ themesNotCategorized.Title }}</legend>
                                    <div class="row text-center">
                                        <ul 
                                            class="list-group list-group-horizontal drop"
                                            @dragenter.prevent
                                            @dragover.prevent
                                            @drop="drop($event, themesNotCategorized)"
                                        >
                                            <li 
                                                class="list-group-item" 
                                                draggable="true" 
                                                v-for="theme in themesNotCategorized.extension" 
                                                :key="theme.id"
                                                @dragend="dragEnd"
                                                @dragstart="dragStart($event, theme)"
                                            >
                                                <button 
                                                    @click.prevent="selectTheme($event, theme)" 
                                                    :class="{'active': theme.selected}" 
                                                    class="btn btn-outline-info"
                                                >
                                                    {{theme.Title}}
                                                    <router-link 
                                                        :to="{
                                                            name: 'questionnaire', 
                                                            params:{
                                                                'id': theme.id
                                                            }
                                                        }"
                                                    >
                                                    <i class="fas fa-edit"></i>
                                                    </router-link>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-7 ">
                    <div class=" p-3 pl-5 pr-5">
                        <h3 class="pb-4 textTitle">Thèmes par catégorie</h3>
                        <br>
                        <select class="form-control pl-5 pr-5" id="recruteur" v-model="categorie" @change = "onchangeCategory" >
                            <option value="">Tous les thèmes</option>
                            <option v-for="categorie in categories" :key="categorie.id" :value="categorie.id" >{{categorie.title}} </option>
                        </select>
                        
                        <div class="row p-5 " v-if="categorie!=''">
                            
                            <router-link to="/addQuestionnaire"><button class="btn btn-primary " >+ &nbsp; Nouveau Questionnaire</button></router-link>
                            <hr>
                            <ul  class="list-group" v-for="theme in themeFiltered" :key="theme.id">
                                <li class="list-group-item ">
                                    <router-link :to="{name: 'questionnaire', params:{'id': theme.id}}"><button class="btn btn-outline-info">{{theme.Title}}</button></router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="row" v-else>
                            <br>
                            <router-link to="/addQuestionnaire"><button class="btn btn-primary mt-3 ml-3" >+ &nbsp; Nouveau Questionnaire</button></router-link>
                            <div class="col-12 " v-for="categorie in categories" :key="categorie.id">
                                <fieldset class="border pr-5 pl-5 pb-4 pt-2">
                                    <legend class="w-auto pt-4 text-white">{{ categorie.title }}</legend>
                                    <div class="row text-center">
                                        <ul 
                                            class="list-group list-group-horizontal drop"
                                            @dragenter.prevent
                                            @dragover.prevent
                                            @drop="drop($event, categorie)"
                                        >
                                            <li 
                                                class="list-group-item" 
                                                style="background-color: rgba(255, 255, 255, 0);"
                                                draggable="true" 
                                                v-for="theme in categorie.extension" 
                                                :key="theme.id"
                                                @dragstart="dragStart($event, theme)"
                                                @dragend="dragEnd"
                                            >
                                                <button 
                                                    @click.prevent="selectTheme($event, theme)" 
                                                    :class="{'active': theme.selected}" 
                                                    class="btn btn-outline-info"
                                                >
                                                    {{theme.Title}}
                                                    <router-link 
                                                        :to="{
                                                            name: 'questionnaire', 
                                                            params:{
                                                                'id': theme.id
                                                            }
                                                        }"
                                                    >
                                                    <i class="fas fa-edit"></i>
                                                    </router-link>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
    <Chatroom />

</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import axios from 'axios';
    import data from '@/data/data';
    import BackButton from '@/components/shared/BackButton.vue';
</script>
      
<script>
      export default {
        name: 'QuizzesManagement',
        props: {
         
        },
        components:{
            Header,
        },
        data(){
            return{
                user : store.state.user,
                categorie : "",
                categories : [], 
                themes : [], 
                themeFiltered : [],

                search:"",
                listCandidate: [], 
                jobs : [], 
                job : "",
                questionnaireAssigned : [],

                popupTrigger : false,
                selectedThemes: [],
                themesNotCategorized: {}
                
            }
        },
        async mounted(){
            if(this.user.profile.user_rights_id<=2 ) this.$router.push("/")
            axios.get(data.baseUrl+"authentication/api/api/jobs")
            .then(response=>{
                this.jobs = response.data
            }).catch(error=> console.log(error))
            await axios.get(data.baseUrl+"thotea/api/questionnaires")
            .then(response=>{
                 this.themes = response.data
                 this.themeFiltered = this.themes
            }).catch(error=> console.log(error))
            const categories = (await axios.get(data.baseUrl+"thotea/api/categories")).data
            this.categories = categories
            axios.get(data.baseUrl+"authentication/api/api/allUsers/")
            .then(response=>{
                this.candidates = response.data
                .filter(item=>{
                    return item.extension[0]!=undefined
                })
                this.listCandidate = this.candidates
            }).catch(error=> console.log(error))
            this.themesNotCategorized = {
                id: null,
                Title: 'Themes sans categories',
                extension:  this.themes.filter(theme=>theme.category===null)
            }
        },
        methods:{
            onchangeCategory(){
                if(this.categorie!=""){
                    this.themeFiltered = this.themes.filter(item=>{
                        return item.category==parseInt(this.categorie)
                    })
                } 
                else this.themeFiltered = this.themes
            },
            searching(){
                if(this.search=="") this.listCandidate = this.candidates
                else{
                    this.listCandidate = this.candidates.filter(item=>{
                        return item.last_name.toLowerCase().includes(this.search.toLowerCase()) || item.first_name.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
            },
            themeByCategorie(categ){
                return this.themes.filter(item=>{
                    return item.category==parseInt(categ)
                })
            }, 
            changeJob(){
                axios.get(data.baseUrl+"thotea/api/questionnaires")
                .then(response=>{
           
                    this.questionnaireAssigned= response.data.filter(item=>{
                        return item.linkedJob==this.job
                    })
                }).catch(error=> console.log(error))
                
            },
            togglePopup(){
                this.popupTrigger=!this.popupTrigger
            },
            selectTheme(e, elt){
                if (!this.selectedThemes.includes(elt)){
                    elt.selected = true
                    this.selectedThemes.push(elt)
                }
                else{
                    this.selectedThemes = this.selectedThemes.filter(element=> element!==elt)
                    elt.selected = false
                }
            },
            dragEnd(){
                document.querySelectorAll('.drop-zone').forEach(elt=>{
                    elt.classList.remove('drop-zone')
                })
            },
            dragStart(e, elt){
                document.querySelectorAll('.drop').forEach(elt=>{
                    elt.classList.add('drop-zone')
                })
                if (!this.selectedThemes.includes(elt)){
                    elt.selected = true
                    this.selectedThemes.push(elt)
                }
                e.dataTransfer.dropEffect = 'move'
                e.dataTransfer.effectAllowed = 'move'
            },
            drop(e, l){
                
                this.categories.concat(this.themesNotCategorized).forEach(category=>{
                    if (category!==l){
                        category.extension = category.extension.filter(theme=>!this.selectedThemes.includes(theme))
                    }
                })
                 this.selectedThemes.forEach( async theme=>{
                    theme.selected=false
                    if (!l.extension.includes(theme)){
                        l.extension.push(theme)
                        await axios.patch(data.baseUrl+`thotea/api/questionnaires/${theme.id}/`, {category: l.id})
                    }
                })
                this.selectedThemes = []
            }
        },
        
        
      }
</script>
<style>
.case{
    background-color:#ffffff33;
    border-radius: 1%;
}
.navCandidate{
    background-color: rgb(43, 124, 124);
    color: white;
}
.style{
    background-color: rgb(242, 196, 14)
}
.list-group{
    flex-wrap: wrap
}
.drop-zone{
    border: 2px solid #390;
    min-height: 100px;
    width: 100%;
    padding: 10px;
    background-color: #4a11
}
@import "~bootstrap/dist/css/bootstrap.css";
</style>