import data from "@/data/data"
import axios from 'axios'

import { useToast } from "vue-toastification";
const toast = useToast()
export async function getHistoriesByPerson (nom, prenom){
    try{
        var payload = {
            "nom" : nom, 
            "prenom" : prenom
        }
        console.log(payload)
        console.log(data.baseUrl + "/ness/historiques_per_profil/")
          const response = await axios.post(data.baseUrl + "/ness/historiques_per_profil/", payload)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}

export async function getHistories (id){
    try{
        var payload = {
            "user-id" : id, 
        }
          const response = await axios.post(data.baseUrl + "/ness/lhpug/", payload)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getHistories2 (){
    try{
        
          const response = await axios.get(data.baseUrl + "/ness/last_histo_per_profils/")
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getUsers (){
    try{
       
          const response = await axios.get(data.baseUrl + "/ness/admin/users/")
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
      
}

export async function typeReseaux (){
    try{
       
          const response = await axios.get(data.baseUrl + "/ness/api/type_reseau/")
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
      
}

export async function reseauxByType(type){
    try{
       
          const response = await axios.get(data.baseUrl + "/ness/api/reseaux/?type_reseau_id="+type)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
      
}
export async function addReseau(payload){
    try{
          const response = await axios.post(data.baseUrl + "/ness/admin/notification/add/", payload)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getReseaux(){
    try{
          const response = await axios.get(data.baseUrl + "/ness/admin/notification/")
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getHistory(id){
    try{
        var payload = {
            "pk" : id
        }
          const response = await axios.post(data.baseUrl + "ness/resultat/", payload)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getIdHistory(payload){
    try{
       
          const response = await axios.post(data.baseUrl + "ness/direct/", payload)
      
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}
export async function getCountries(){
    try{
        const response = await axios.get(data.baseUrl + "/ness/api/pays/", )
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getSecteur(){
    try{
        const response = await axios.get(data.baseUrl + "/ness/api/secteur/", )
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function addFavori(idCandidate,idUser){
    try{
        var payload= {
            "user" : idUser, 
            "candidat" : idCandidate
        }
        console.log("payload", payload)
        const response = await axios.post(data.baseUrl + "/ness/admin/favori/add/",payload )
        console.log(response.data)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getFavoris(idUser){
    try{
        var payload= {
            user : idUser, 
        }
        const response = await axios.post(data.baseUrl + "/ness/admin/favori/self/",payload )
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function removeFavori( idCandidate, idUser){
    try{
        var payload= {
            user : idUser, 
            candidat : idCandidate
        }
        
        const response = await axios.post(data.baseUrl + "/ness/admin/favori/remove/",payload )
        console.log(response.data)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function getAllUsers(){
    try{
        
        const response = await axios.get(data.baseUrl+"authentication/api/api/allUsers/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function getAllGroups(){
    try{
        
        const response = await axios.get(data.baseUrl+"authentication/api/api/user-groups/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function resetGroup(payload) {
    try{
        const response = await axios.post(data.baseUrl+"authentication/api/reset-user-groups/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function userRights() {
    try{
        const response = await axios.get(data.baseUrl+"authentication/api/api/userrights/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function updateUserRights(idUser, payload) {
    try{
        const response = await axios.put(data.baseUrl+"authentication/api/api/userrights/"+idUser+"/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function updateRequest(payload){
    try{
       
        const response = await axios.post(data.baseUrl+"/ness/admin/notification/alter/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function getWaitingNetworks(){
    try{
        const response = await axios.get(data.baseUrl+"/ness/admin/notification/wait-refused/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function getNotifications(){
    try{
        const response = await axios.get(data.baseUrl+"/ness/admin/notification/todo/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getParameter(idReseau){
    try{
        const response = await axios.get(data.baseUrl+"/ness/admin/notification/sector/?pk="+idReseau)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function updateNetwork(payload){
    try{
        const response = await axios.post(data.baseUrl+"/ness/admin/reseaux/update/actif/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function updateCoefficient(payload){
    try{
        
        const response = await axios.post(data.baseUrl+"/ness/admin/reseaux/update/coeff/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function getUserById(id){
    try{
        const response = await axios.get(data.baseUrl+"authentication/api/api/allusersness?id="+id)
        console.log(response)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function getUserNess(){
    try{
        const response = await axios.get(data.baseUrl+"authentication/api/api/allusersness")
        // console.log(response)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}



export async function getVlinks(){
    try{
        const response = await axios.get(data.baseUrl+"ness/api/vlinks/")
        // console.log(response)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}