import axios from 'axios'
import data from '@/data/data'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import jwt_decode from 'jwt-decode'

let user = null
try{
  user = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  
}catch(e){
  console.error(e)
}

if(!user){
  user = undefined
}

export default createStore({
  state: {
    user : user,
    showCollab: false,
  },
  getters : {
  },
  mutations: {
   
    UPDATE_USER(state, user){
      state.user = user
    },
    toggleShowCollab(state, showCollab){
      state.showCollab = showCollab
    }
    
  },
  actions: {
    login(context, info){
      return new Promise((resolve, reject)=>{
        // console.log("TOKENE ::: ", info)
        axios.post(data.baseUrl + "api/token/",info)
        .then(response=> {
          console.log(response.data)
          if(!response.data.Error){
            const decoded = jwt_decode(response.data.access);
            const {user_id} = decoded
            
            axios.post(data.baseUrl + "authentication/api/user/",{"id": user_id})
              .then(response1=>{
                // console.log(response1.data)
                  context.commit('UPDATE_USER', response1.data)
                  localStorage.setItem('user', user)
                  resolve(response)
              })
              .catch(error1=>{
                console.log("Erreur : " + error1)
                reject(error1)
              })
            
          }else{
            resolve(response)
          }
        })
        .catch(error=> {
          
          reject(error)
        }) 
      })
    },
    logAfterRegister(context, user){
        context.commit('UPDATE_USER', user)
        localStorage.setItem('user', user)
    },
    logout(context){
        localStorage.removeItem('user')
        context.commit('UPDATE_USER', undefined)
    }
    
  },
  modules: {
  },
  plugins : [createPersistedState()],
})
