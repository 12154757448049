<template>
  <div class="app" id="ness">
    <Header :user="user" page="histories" logo="ness"/>
    <div class="row">
      <back-button />
      <br>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-12 col-md-4"></div>
      <div class="col-12 col-md-1 text-center">
        <i class="fa-solid fa-magnifying-glass mt-1" style="color: white; font-size: xx-large;"></i>
      </div>
      <div class="col-12 col-md-3">
        <input class="form-control fieldLogin" placeholder="Nom, Prénom..." v-model="searchHistory"/>
      </div>
      <div class="col-12 col-md-4 text-center mt-2 mt-md-0">
        <button class="btn" :class="!mesFavoris ? 'btn-outline-warning' : 'btn-warning'" @click="mesFavoris = !mesFavoris">
          <i class="fa-solid fa-star"></i> Mes favoris
        </button>
      </div>
    </div>
    
    <div class="row p-5 m-1" style="max-height: 100vh; overflow-y: auto; padding-left: 5%; padding-right: 5%;">
      <div class="col-12 col-md-2 col-lg-2a p-4" v-for="history in filteredHistories()" :key="history._id">
        <div class="card p-2" style="border-radius: 15%;">
          <div class="row float-right" style="margin-top: -15%; margin-left: 60%;">
            <button class="btn" style="float: right!important" @click="addFavori(history.id)">
              <i class="fa-solid fa-bookmark" :id="'icon-' + history.id" :style="!isFavori(history.id) ? 'color: rgb(131, 133, 133)' : 'color: gold'" style="font-size: xx-large;"></i>
            </button>
          </div>
          <b style="float: left">{{ history.prenom }}</b>
          <b style="float: left">{{ history.nom }}</b>
          <br>
          <p>{{ history.date_histo.substring(0, 10) }}</p>
          <div class="row">
            <div class="col-md-4">
              <i class="fa-solid fa-link" style="color: gold;"></i>
            </div>
            <div class="col-md-8">
              <b style="color: gold;">VLINKS {{ history.vlinks.toFixed(1) }}</b>
            </div>
          </div>
          <br>
          <div class="row justify-content-center">
            <router-link :to="'/ness/histories/' + history.id_histo">
              <button class="btn rounded-circle btn-sm align-items-center" style="background-color: darkcyan;">
                <i class="fa-solid fa-arrow-right" color="white"></i>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Chatroom/>
</template>

  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import Chatroom from '@/components/chatroom/Chatroom.vue';
  import store from '@/store';
  import {addFavori, getFavoris, removeFavori, getHistories2} from '@/data/apiNess'
  import { useToast } from "vue-toastification";
  const toast = useToast()
  export default {
    name: "reseauxNess",
    props: {},
  
    components: {
      BackButton,
      Chatroom,
      Header,
  },
  
    data() {
      return {
        user : store.state.user, 
        histories: [], 
        searchHistory : '', 
        favoris : [], 
        mesFavoris : false
      };
    },
    async mounted(){
      this.favoris = await getFavoris(this.user.Id)
      // this.histories = await getHistories(this.user.Id)
      this.histories = await getHistories2()
    },
    methods:{
      filteredHistories(){
        if(this.searchHistory!='')
          return this.histories.filter(item=>{
            return item.nom.toLowerCase().includes(this.searchHistory.toLowerCase()) || item.prenom.toLowerCase().includes(this.searchHistory.toLowerCase())
          })
        if(this.mesFavoris){
          return this.histories.filter(item=>{
            return this.isFavori(item.id)
          })
        }
        return this.histories
      }, 
      async addFavori(idCandidat){

        let response = {}
        const index = this.favoris.findIndex(item => item.id_candidat == idCandidat);
        
        if(index==-1){
          // console.log("ADD icon-"+idCandidat)
          response = await addFavori(idCandidat, this.user.Id)
          
          document.getElementById('icon-'+idCandidat).style.color = 'gold'
          this.favoris.push({ id_candidat: idCandidat})
        }
        else {
          // console.log("REMOVE icon-"+idCandidat)
          document.getElementById('icon-'+idCandidat).style.color ='rgb(131, 133, 133)'
          response = await removeFavori(idCandidat, this.user.Id)
          this.favoris.splice(index, 1)
        }
        toast.success(JSON.stringify(response))
      },
      isFavori(idCandidat){
        const index = this.favoris.findIndex(item=>{
          return item.id_candidat == idCandidat
        })

        if(index!=-1) return true
        else return false
      }
    }
  
  };
  </script>
  <style>
  ::-ms-input-placeholder {
    color: white;
  }
</style>