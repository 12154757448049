<template>
    <nav class="navbar thoteaBg">
        <a class="navbar-brand text-white "  href="#">
            <a @click="showMenu()"><img class="float-left logo-img"  src="@/assets/Logo_BULMA.png"/></a> 
            <!-- <a href="/home"><img class="float-left logo-img"  src="@/assets/Logo_BULMA.png"/></a>   -->
            <a :href="linkDashboard" ><img class="ml-5 float-left logo-img2"  :src="require('@/assets/logos/'+logo+'.png')" /></a>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="!showCollab"><router-link :class="page==='test'? 'activeSpan' : 'text-white'" to="/" >Tests</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="showCollab" ><router-link :class="page==='candidat'? 'activeSpan' : 'text-white'" to="/candidates" >Candidats</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="showCollab"><router-link :class="page==='questionnaire'? 'activeSpan' : 'text-white'" to="/listQuestionnaire">Questionnaires</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='thotea'" v-show="!showCollab" ><router-link :class="page==='resultat'? 'activeSpan' : 'text-white'" to="/ResultPage">Résultats</router-link></span>

                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="!showCollab"><router-link :class="page==='search'? 'activeSpan' : 'text-white'" to="/ness/search" >Recherche</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="!showCollab"><router-link :class="page==='histories'? 'activeSpan' : 'text-white'" to="/ness/histories" >Historique</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='ness'" v-show="!showCollab" ><router-link :class="page==='reseaux'? 'activeSpan' : 'text-white'" to="/ness/networks">Réseaux</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="showCollab" ><router-link :class="page==='requests'? 'activeSpan' : 'text-white'" to="/ness/requests" >Nouvelles demandes <span class="badge">{{count}}</span></router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="showCollab"><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/ness/users" >Utilisateurs</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='ness'" v-show="showCollab"><router-link :class="page==='reseaux'? 'activeSpan' : 'text-white'" to="/ness/gest-networks">Réseaux</router-link></span>

                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='codeTravail'? 'activeSpan' : 'text-white'" to="/themis/laborcode" >Code du travail</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='agreements'? 'activeSpan' : 'text-white'" to="/themis/collective-agreements" >Conventions collectives</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;" ><router-link :class="page==='entreprise'? 'activeSpan' : 'text-white'" to="/themis/my-company">Mon entreprise</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='faq'? 'activeSpan' : 'text-white'" to="/themis/faq">FAQ</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/themis/users" >Utilisateurs</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='entreprise'? 'activeSpan' : 'text-white'" to="/themis/my-company">Mon entreprise</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='faq'? 'activeSpan' : 'text-white'" to="/themis/faq-gest">FAQ</router-link></span>
                
                <span class="ml-5 float-left pt-3" v-if="logo=='pythie'" v-show="!showCollab"><router-link :class="page==='user-profile'? 'activeSpan' : 'text-white'" to="/pythie/user-profile" >Profil</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='pythie'" v-show="!showCollab"><router-link :class="page==='plan'? 'activeSpan' : 'text-white'" to="/pythie/career-plan" >Plan de carrière</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='pythie'" v-show="!showCollab" ><router-link :class="page==='suivi'? 'activeSpan' : 'text-white'" to="/pythie/activities-tracking">Suivi d'activité</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='pythie'" v-show="showCollab" ><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/pythie/users">Utilisateurs</router-link></span>
                
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
            </a>
        <div>
            
            <!-- <a style="cursor: pointer;" @click="logout()" v-if="user && user.Id"  data-toggle="tooltip" data-placement="top" title="Se déconnecter">{{ user.username }}<img src="@/assets/profil_photo.png"/></a> -->
            <div class="dropdown" v-if="user && user.Id">
                <router-link to="/themis/notification" v-show="showCollab" v-if="logo=='themis'" class="mr-3"> <i class="fa-solid fa-bell mt-2" style="font-size: xx-large;"></i><small class="badge" style="background-color: red;margin-left: -4% !important;margin-top: -10px;">{{ notificationCount }}</small></router-link>
            <router-link :to="linkProfile"><span class="btnProfil mr-2 mt-2 text-white">PROFIL</span> </router-link>
                <a class="btn text-white navbar-brand dropdown-toggle" 
                    role="button" data-toggle="dropdown" aria-expanded="false" 
                    @click.prevent="toggleDropdown">
                    {{ user.Username }}
                    <img src="@/assets/profil_photo.png"/>
                </a>

            <div class="dropdown-menu is-right" :style="{display: show?'block':null, left:'auto',
                right:0}" >
                <a  v-if="logo=='themis'" class="dropdown-item" href="/themis/favoris"><i class="fa-solid fa-star" color="yellow"></i> Favoris </a>
                <a class="dropdown-item" :href="linkProfile">Profil</a>
                <a class="dropdown-item" v-if="user.profile.user_rights_id>2" :href="linkDashboard" @click="toggleView" >{{ otherRole }}</a>
                <router-link class="dropdown-item" v-if="user.profile.user_rights_id>2 && logo=='thotea'" :to="{name: 'quizz-management'}">Gestion des questionnaires</router-link>
                <a class="dropdown-item" href="#" @click="logout()">Se déconnecter</a>
            </div>
        </div>
        </div>
    </nav>
    <div v-if="menuActive" class="appList p-3">
        <div class="row pr-3 pl-3 pt-3">
            <div class="col-md-8">
                <div class="row">
                    <a @click="showMenu()"><img src="@/assets/icon_fleche.png" style="width: 70px;"/></a>
                </div>
                <div class="row">
                    <div class="col-md-3 text-center" @mouseover="selectedApp='thotea'">
                        <b class="activeSpan">THOTEA</b>
                        <br><br>
                        <div class="sous-menu">
                            <p  v-show="!showCollab"><router-link class="activeSpan"  to="/" >Tests</router-link></p>
                            <p   v-show="showCollab" ><router-link class="activeSpan" to="/candidates" >Candidats</router-link></p>
                            <p  v-show="showCollab"><router-link class="activeSpan" to="/listQuestionnaire">Questionnaires</router-link></p>
                            <p v-show="!showCollab"><router-link class="activeSpan" to="/ResultPage">Résultats</router-link></p>
                        </div>
                    </div>
                    <div class="col-md-3"  @mouseover="selectedApp='themis'">
                        <b class="activeSpan">THEMIS</b>
                        <br><br>
                        <div class="sous-menu">
                            <p v-show="!showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/laborcode" >Code du travail</router-link></p>
                            <p v-show="!showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/collective-agreements" >Conventions collectives</router-link></p>
                            <p v-show="!showCollab" style="font-size: large;" ><router-link class="activeSpan" to="/themis/my-company">Mon entreprise</router-link></p>
                            <p v-show="!showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/faq">FAQ</router-link></p>
                            <p  v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/users" >Utilisateurs</router-link></p>
                            <p  v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/my-company">Mon entreprise</router-link></p>
                            <p v-show="showCollab" style="font-size: large;"><router-link class="activeSpan" to="/themis/faq-gest">FAQ</router-link></p>
                        </div>
                    </div>
                    <div class="col-md-3" @mouseover="selectedApp='pythie', console.log(selectedApp)">
                        <b class="activeSpan">PYTHIE</b>
                        <br><br>
                        <div class="sous-menu">
                            <p v-show="!showCollab"><router-link class="activeSpan" to="/pythie/user-profile" >Profil</router-link></p>
                            <p v-show="!showCollab"><router-link class="activeSpan" to="/pythie/career-plan" >Plan de carrière</router-link></p>
                            <p v-show="!showCollab" ><router-link class="activeSpan" to="/pythie/activities-tracking">Suivi d'activité</router-link></p>
                            <p v-show="showCollab" ><router-link class="activeSpan" to="/pythie/users">Utilisateurs</router-link></p>
                        </div>
                    </div>
                    <div class="col-md-3" @mouseover="selectedApp='ness';">
                        <b class="activeSpan">NESS</b>
                        <br><br>
                        <div class="sous-menu">
                            <p v-show="!showCollab"><router-link class="activeSpan" to="/ness/search" >Recherche</router-link></p>
                            <p v-show="!showCollab"><router-link class="activeSpan" to="/ness/histories" >Historique</router-link></p>
                            <p v-show="!showCollab" ><router-link class="activeSpan" to="/ness/networks">Réseaux</router-link></p>
                            <p v-show="showCollab" ><router-link class="activeSpan" to="/ness/requests" >Nouvelles demandes <span class="badge">{{count}}</span></router-link></p>
                            <p v-show="showCollab"><router-link class="activeSpan" to="/ness/users" >Utilisateurs</router-link></p>
                            <p v-show="showCollab"><router-link class="activeSpan" to="/ness/gest-networks">Réseaux</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <img  v-show="!showCollab" :src="require('@/assets/captures/'+selectedApp+'.png')" style="width: 100%;margin-top: 5%;" />
                <img  v-show="showCollab" :src="require('@/assets/captures/'+selectedApp+'1.png')" style="width: 100%;margin-top: 5%;" />
            </div>
        </div>
        <div class="row">
            <a href="/home"><img src="@/assets/home_page.png" style="width: 70px;"/></a>
        </div>
        
    </div>
    <link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>
</template>
<script>
import store from '@/store'
import {getWaitingNetworks} from '@/data/apiNess'
import {getNotification} from '@/data/apiThemis'
     export default{
        name : 'headerComponent',
        props:{
            user : Object, 
            page : String,
            logo : String
        },
        data(){
            return {
                show: false,
                showCollab : store.state.showCollab, 
                linkDashboard : '', 
                linkProfile : '', 
                reseaux : [], count : 0, 
                notificationCount : 0, 
                menuActive : false, 
                selectedApp : 'thotea',
            }
        },
        async mounted(){
            // if(this.user==undefined){
            //     this.$router.push("/login")
            // }
            if(this.logo == 'ness'){
                this.linkDashboard = '/ness/dashboard'
                this.linkProfile = '/ness/profile'
                this.reseaux = await getWaitingNetworks()
                this.reseaux.map(item=>{
                    if(item.validation==null){
                        this.count ++
                    }
                })
            }else if(this.logo=='thotea'){
                this.linkDashboard = '/dashboard'
                this.linkProfile = '/profil'
            }
            else{
                this.linkDashboard = '/'+this.logo
                this.linkProfile = '/'+this.logo+'/profile'
            }

            if(this.logo=='themis'){
                const notification = await getNotification()
                const newNotif = notification.filter(item=>{
                    return item.isActive 
                })
                this.notificationCount = newNotif.length
            }
        },
        methods:{
            logout(){
                this.$store.dispatch('logout')
                .then(response=> {
                    console.log(response)
                    this.toggleViewWithBool(false)
                    this.$router.push('/login')
                }).catch(error=>{
                    console.log(error)
                })
            },
            toggleDropdown(){
                this.show = !this.show
            },
            toggleView(){
                this.$store.commit('toggleShowCollab', !this.$store.state.showCollab)
            },
            toggleViewWithBool(bool){
                this.$store.commit('toggleShowCollab', bool)
            }, 
            showMenu(){
                this.menuActive = !this.menuActive
            }
        },
        computed:{
            otherRole(){
                if(this.logo=='thotea')
                    return this.$store.state.showCollab?"Candidat":"Gestionnaire"
                else 
                    return this.$store.state.showCollab?"Collaborateur":"Gestionnaire"
            }
        }
     }
</script>
<style>
    .float-left{
        float: left;
    }
    .btnProfil{
        
        padding: 10px;
        border-radius: 10% / 60%;
        border: 1px solid ;
        margin-top: 5px!important;
    }
    .activeSpan{
        /* padding: 10px; */
        /* background-color: #fab833; */
        border-radius: 4%;
        color: #fab833!important;
    }
    .logo-img {
        /* width: 10%;  */
        margin-top: 1%;
        width: 70px;
    }
    .logo-img2 {
        /* width: 5%;  */
       width: 60px;
    }
    .appList{
        top:-2%;
        position: fixed;
        z-index: 1000; 
        /* margin-top: -7%; */
    }
    .appList .row{
        background-color: white;
        padding: 10px;
        cursor: pointer;
    }
    .sous-menu router-link{
        color: #fab833;
    }
    
</style>